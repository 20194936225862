body{
  margin:0;
  padding: 0;
}
.container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  min-width: 1200px;
  max-width: 1200px;
}
.containerItem{
  border: 3px solid #fdf9de;
  border-radius: 7px;
  width: 300px;
  margin: 5px;
  text-align: center;
  background-color: #fdf9de;
  padding: 10px 10px;
}
.containerItem:hover{
  background-color:#e0f5d4;
}
h1{
  text-align: center;
  margin-top: 120px;
}
img {
  width: 100%;
  height: auto;
  border-radius: 7px;
  height: 200px;
}
.goodsName{
  font-size: 26px;
  margin: 10px auto;
  font-weight: bold;
}
.priceTag{
  font-size: 22px;
  margin: 10px auto;
  font-weight: bold;
}
.addToCart{
  display: block;
  margin: 25px auto;
  font-size: 20px;
  font-weight: bold;
  
}
.addToCartBtn{
  padding: 15px 20px;
  border-radius: 6px;
  background-color: #C4BA3B;
  color: #000;
  cursor: pointer;
}

.subtractBtn{
  background-color: #C4BA3B;
  font-weight: bold;
  color: #000;
  border-radius: 6px;
  padding: 5px 10px;
  margin-right: 7px;
  margin-left: 10px;
  cursor: pointer;
}
.addBtn{
  background-color: #C4BA3B;
  font-weight: bold;
  color: #000;
  border-radius: 6px;
  padding: 5px 10px;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
}
.backToGoodsListBtn{
  background-color: #C4BA3B;
  font-weight: bold;
  border-radius: 6px;
  margin: 50px auto;
  cursor: pointer;
  padding: 13px 40px;
  text-align: center;
  width: 300px;
  text-align: center;
  color: #000;
  text-decoration: none;
}
.productName{
  margin-top:15px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 10px;
}
.ProductDetail{
  margin: 10px auto;
  width: 1000px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;

}
.ProductDetail img{
  width:350px;
}
.ProductDetail td{
  padding : 10px;
}
.cartSection{
  width: 70%;
  flex-wrap: wrap;
  display: flex;
  margin: 10px auto;

}
.checkoutTable{
  width: 900px;
  text-align: left;
  border-spacing: 0px;
  margin-left: 100px;
  
}
.checkoutTable tr{
  background-color: #FFFEE6;
  margin: 10px 10px;
}
.checkoutTable td{
  padding:0px;
  font-size: 16px;
}
.checkoutTable img{
  width: 300px;
  margin: 15px;
}
.productSubTotal{
  font-size: 30px;
  font-weight: bold;
  margin-right: 30px;
  display: block;
}
.checkoutSection{
  width: 30%;
  font-size: 20px;
  text-align: center;

}
.checkoutSection div{
  margin: 12px 30px;
}
.checkoutSection button{
  font-size: 22px;
  background-color: #C4BA3B;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  padding: 13px 40px;
  text-align: center;
  text-align: center;
  color: #000;
  text-decoration: none;
  line-height: 5px;
}
.grandTotal{
  font-size: 42px;
  font-weight: bold;
}
.nothingInCart{
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
}
.freeShipping{
  font-weight: bold;
  color: forestgreen;
}
.noShipping{
  color: brown;
  font-weight: bold;
}
nav{
  position: fixed;
  text-align: center;
  top: 0;
  padding: 30px 0px;
  width: 100%;
  background-color: #C4BA3B;
}
nav a{
  font-size: 20px;
  text-decoration: none;
  margin: 10px 10px;
  padding: 13px 20px;
  background-color: #fdf9de;
  border-radius: 8px;
  font-weight: bold;
  color: black;
}
nav a:visited{
  color: black;
}
.introduce{
  color: black;
  line-height: 15px;
  font-family:"arial"
}
.introduceButton{
  padding: 5px 5px;
  border-radius: 6px;
  background-color: #C4BA3B;
  color: #000;
  cursor: pointer;
}
.introduceButton2{
  padding: 5px 5px;
  border-radius: 6px;
  background-color: #fdf9de;
  color: #000;
  cursor: pointer;
}
